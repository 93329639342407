@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_book.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_book_it.otf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_light.otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_light_it.otf');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_med.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_med_it.otf');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_black.otf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_black_it.otf');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_bold.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Crossten';
  src: url('../fonts/Crossten/crossten_bold_it.otf');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Acumin';
  src: url('../fonts/Acumin/AcuminVariableConcept.otf');
  /* IE9 Compat Modes */
  font-display: swap;
}
.h1 {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 93px;
  letter-spacing: 0px;
}
.h2 {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 80px;
  letter-spacing: 0px;
}
.h3 {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 60px;
  letter-spacing: 0px;
}
/*nav*/
.ac-navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  transition: top 0.3s ease-in;
  /*div*/
}
.ac-navigation.is--hide {
  top: -110px;
}
@media only screen and (min-width: 40.063em) {
  .ac-navigation.is--hide {
    top: 0;
  }
}
.ac-navigation__logo {
  position: absolute;
  top: 20px;
  left: 20px;
  display: block;
  width: 75px;
}
@media only screen and (min-width: 40.063em) {
  .ac-navigation__logo {
    width: 100px;
  }
}
.ac-navigation__logo img {
  width: 100%;
}
.ac-navigation__logo span {
  display: block;
  width: 60px;
  position: absolute;
  bottom: -5px;
  left: 70px;
}
@media only screen and (min-width: 40.063em) {
  .ac-navigation__logo span {
    width: 80px;
    left: 90px;
  }
}
.ac-navigation.is--open label {
  font-size: 0;
}
.ac-navigation.is--open label .ac-navigation__icon span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 20%;
  top: 50%;
}
.ac-navigation.is--open label .ac-navigation__icon span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 19%;
  bottom: 48%;
}
.ac-navigation.is--open .ac-navigation__wrapper {
  width: 100vw;
  height: 100vh;
  transition: background-color 0.3s ease-out;
  background-color: #FFFFFF;
}
.ac-navigation.is--open .ac-navigation__wrapper ol {
  display: block;
}
.ac-navigation.is--open .ac-navigation__wrapper ol li {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
.ac-navigation.is--open .ac-navigation__wrapper ol li:nth-child(1) {
  transition-delay: 0.2s;
}
.ac-navigation.is--open .ac-navigation__wrapper ol li:nth-child(2) {
  transition-delay: 0.4s;
}
.ac-navigation.is--open .ac-navigation__wrapper ol li:nth-child(3) {
  transition-delay: 0.6s;
}
.ac-navigation.is--open .ac-navigation__wrapper ol li:nth-child(4) {
  transition-delay: 0.8s;
}
.ac-navigation__btn {
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  border: none;
  top: 45px;
  right: 20px;
}
.ac-navigation__btn .ac-navigation__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
  border: 1px solid #000000;
}
.ac-navigation__btn .ac-navigation__icon span {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  border-radius: 0;
  margin: 0;
  border: none;
  width: 18px;
  height: 1px;
  background-color: #000000;
}
.ac-navigation__btn .ac-navigation__icon span:nth-child(1) {
  top: 35%;
}
.ac-navigation__btn .ac-navigation__icon span:nth-child(2) {
  bottom: 35%;
}
.ac-navigation__wrapper {
  background-color: transparent;
}
.ac-navigation__wrapper ol {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  counter-reset: navigation;
}
.ac-navigation__wrapper ol li {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 93px;
  letter-spacing: 0px;
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  position: relative;
  text-transform: uppercase;
  font-style: italic;
  opacity: 0;
}
.ac-navigation__wrapper ol li::before {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  bottom: 21px;
  left: -30px;
  counter-increment: navigation;
  content: counters(navigation, ".", decimal-leading-zero) " ";
  font-size: 16px;
  color: #0017E8;
  margin-right: 16px;
}
.ac-navigation__wrapper ol li a {
  color: transparent;
  -webkit-text-fill-color: transparent;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000000;
  text-decoration: none;
}
* {
  box-sizing: border-box;
  text-rendering: geometricprecision;
}
#root {
  width: 100vw;
  overflow-x: hidden;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active input:-internal-autofill-selected {
  -webkit-box-shadow: none;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
  margin: 0;
  font-size: 16px;
  width: 100vw;
  overflow-x: hidden;
  background-color: #FFFFFF;
  color: #000000;
}
.body-wrapper.disable-scrolling {
  height: 100vh;
  overflow: hidden;
}
.grid-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}
@media only screen and (min-width: 40.063em) {
  .grid-2 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
.ac-btn {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  display: table;
  background-color: #0017E8;
  color: #FFFFFF;
  text-align: center;
  border-radius: 7px;
  border: none;
  text-decoration: none;
  padding: 10px 20px;
}
.ac-btn.red {
  background-color: #F83A07;
}
.ac-btn.violet {
  background-color: #832A71;
}
.ac-faq {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  padding: 30px 0 30px 34px;
}
.ac-faq:hover {
  background-color: rgba(243, 245, 246);
}
.ac-faq input[type="checkbox"] {
  display: none;
}
.ac-faq input[type="checkbox"]:checked ~ label::before {
  transform: rotate(45deg) translateY(-50%);
  left: -6px;
  top: calc(50% - 3px);
}
.ac-faq input[type="checkbox"]:checked ~ p {
  height: auto;
  margin: 10px 0 0 0;
  line-height: 23px;
}
.ac-faq label {
  cursor: pointer;
  font-weight: 700;
}
.ac-faq label::before {
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 24px;
  height: 24px;
  background-image: url('../images/icons/plus.svg');
}
.ac-faq p {
  margin: 0;
  height: 0;
  overflow: hidden;
}
/*section*/
.ac-home {
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 140px auto 0 auto;
  min-height: calc(100vh - 140px);
  /*span*/
}
.ac-home .ac-logo {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  position: absolute;
  font-size: 650px;
  top: -740px;
  width: 900px;
  height: 900px;
  text-transform: uppercase;
  z-index: -1;
}
@media only screen and (min-width: 40.063em) {
  .ac-home .ac-logo {
    top: -860px;
    top: -789px;
    right: 3%;
  }
}
@media only screen and (min-width: 85.063em) {
  .ac-home .ac-logo {
    right: 4%;
  }
}
.ac-home .ac-logo span {
  padding: 0 40px;
}
.ac-home .ac-logo span:nth-child(1) {
  position: absolute;
  transform: rotate(0deg) skew(10deg, -19deg);
  top: 0;
  background-image: linear-gradient(90deg, #0017E8 1%, #832A71 21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ac-home .ac-logo span:nth-child(2) {
  position: absolute;
  top: 583px;
  left: 244px;
  transform: rotate(-2deg) skew(6deg, 15deg);
  transform: rotate(0deg) skew(12deg, 25deg);
  letter-spacing: -17px;
  background-image: linear-gradient(90deg, #0017E8 1%, #832A71 15%, #F83A07 31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (min-width: 40.063em) {
  .ac-home .ac-logo span:nth-child(2) {
    background-image: linear-gradient(90deg, #0017E8 10%, #832A71 40%, #F83A07 70%);
  }
}
.ac-home__wrapper {
  position: relative;
}
@media only screen and (min-width: 40.063em) {
  .ac-home__wrapper {
    width: 95.5%;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 50px 0 0 0;
  }
}
@media only screen and (min-width: 85.063em) {
  .ac-home__wrapper {
    padding-top: 100px;
  }
}
.ac-home__wrapper h1 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-home__wrapper h1 {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (min-width: 40.063em) {
  .ac-home__wrapper div:nth-child(1) {
    grid-column: 1;
  }
}
.ac-home__wrapper div:nth-child(2) {
  grid-column: 2;
  grid-row: 1 / 3;
}
.ac-home__wrapper div:nth-child(3) {
  text-align: center;
}
@media only screen and (min-width: 40.063em) {
  .ac-home__wrapper div:nth-child(3) {
    text-align: left;
    width: 90%;
    margin: 0;
  }
}
.ac-home p,
.ac-home h2 {
  margin: 0;
}
.ac-home p {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 36px;
  width: 90%;
  max-width: 430px;
  margin: 60px auto 30px auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-home p {
    margin: 0 0 30px 0;
  }
}
.ac-home h2 {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 40px;
  color: #0017E8;
}
@media only screen and (min-width: 40.063em) {
  .ac-home h2 {
    font-size: 63px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
.ac-home .ac-btn {
  display: table;
  margin: 0 auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-home .ac-btn {
    margin-left: 0;
    margin-right: 0;
  }
}
.ac-home img {
  width: 80%;
}
@media only screen and (min-width: 40.063em) {
  .ac-home img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.ac-home ul {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  list-style-type: none;
  margin: 120px 0 60px 0 ;
  padding: 20px 30px;
  background-color: rgba(243, 245, 246);
}
@media only screen and (min-width: 40.063em) {
  .ac-home ul {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 20px 50px;
  }
}
.ac-home ul li {
  margin: 30px 0;
}
@media only screen and (min-width: 40.063em) {
  .ac-home ul li {
    margin: 10px 0;
  }
}
.ac-home ul li img {
  display: block;
  margin: 0 auto;
  height: 40px;
  width: auto;
  object-fit: contain;
  max-width: 135px;
}
/*section*/
.ac-introduction {
  padding: 90px 0;
  /*h3*/
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction {
    padding: 130px 0;
  }
}
.ac-introduction.alternate {
  position: relative;
  padding: 150px 0 100px 0;
  background-color: rgba(243, 245, 246);
}
.ac-introduction.alternate .badge-headline {
  color: #F83A07;
}
.ac-introduction.alternate .badge-headline span {
  background-image: url('../images/icons/stoerer.svg');
}
.ac-introduction.alternate .grid-2 h3,
.ac-introduction.alternate .grid-2 ul li {
  color: #F83A07;
}
.ac-introduction .wrapper {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}
.ac-introduction .grid-2 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: #FFFFFF;
  max-width: 1200px;
  margin: 0 auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction .grid-2 div:nth-of-type(1) {
    max-width: none;
    width: 100%;
  }
}
.ac-introduction .grid-2.ac-action-grid {
  justify-content: center;
  max-width: 470px;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction .grid-2.ac-action-grid {
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -webkit-box-align: start;
    grid-gap: 10px;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: normal;
    -ms-flex-pack: normal;
    justify-content: normal;
  }
}
@media only screen and (min-width: 64.063em) {
  .ac-introduction .grid-2.ac-action-grid {
    -ms-grid-columns: auto 10px auto;
    grid-template-columns: auto auto;
    grid-gap: 10px;
  }
}
@media only screen and (min-width: 85.063em) {
  .ac-introduction .grid-2.ac-action-grid {
    width: 95%;
  }
}
.ac-introduction .grid-2.ac-action-grid div {
  text-align: center;
}
.ac-introduction .grid-2.ac-action-grid div p {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
}
.ac-introduction .grid-2.ac-action-grid div:nth-child(1) span {
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 267px;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction .grid-2.ac-action-grid div:nth-child(1) span {
    margin-left: 0;
    margin-right: 0;
  }
}
.ac-introduction .grid-2.ac-action-grid div:nth-child(1) p {
  color: #832A71;
}
.ac-introduction .grid-2.ac-action-grid div:nth-child(2) span {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.ac-introduction .grid-2.ac-action-grid div:nth-child(2) span .ac-btn {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction .grid-2.ac-action-grid div:nth-child(2) span {
    float: right;
    margin-left: 0;
    margin-right: 0;
    max-width: 151px;
  }
  .ac-introduction .grid-2.ac-action-grid div:nth-child(2) span .ac-btn {
    margin-left: 0;
    margin-right: 0;
  }
}
.ac-introduction .grid-2.ac-action-grid div:nth-child(2) p {
  color: #F83A07;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction .grid-2.ac-action-grid div:nth-child(2) .ac-btn {
    float: right;
  }
}
.ac-introduction .grid-2 ul li {
  color: #0017E8;
}
.ac-introduction .grid-2 h3 {
  color: #0017E8;
}
.ac-introduction .grid-2 h3 span {
  background-image: url('../images/icons/stoerer_blue.svg');
}
.ac-introduction .badge-headline {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 60px;
  letter-spacing: 0px;
  text-transform: uppercase;
  position: relative;
  margin-top: 0;
  margin-bottom: 90px;
  max-width: 300px;
  padding-left: 110px;
  font-size: 50px;
  line-height: 45px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction .badge-headline {
    font-size: 60px;
    line-height: 55px;
    padding-left: 120px;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
}
.ac-introduction .badge-headline span {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0;
  width: 80px;
  height: 80px;
  font-size: 50px;
  line-height: initial;
  margin-right: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFFFFF;
  text-align: center;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction .badge-headline span {
    width: 100px;
    height: 100px;
    font-size: 60px;
  }
}
.ac-introduction p {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 18px;
  line-height: 25px;
}
.ac-introduction p b {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
  display: block;
}
.ac-introduction ul {
  display: -ms-grid;
  display: grid;
  padding: 0;
  margin: 0;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  list-style-type: none;
  text-align: center;
}
.ac-introduction ul li {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 16px;
  line-height: 16px;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction ul li {
    font-size: 23px;
    line-height: 23px;
  }
}
.ac-introduction ul li img {
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto 10px auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction ul li img {
    width: 80px;
    height: 80px;
  }
}
.ac-introduction .ac-btn {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction .ac-btn {
    margin-top: 80px;
  }
}
.ac-introduction .ac-postrona {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}
.ac-introduction .ac-postrona p {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 180px;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
  margin: 0;
  color: #FFFFFF;
  text-transform: uppercase;
}
@media only screen and (min-width: 40.063em) {
  .ac-introduction .ac-postrona p {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
}
/*section*/
.ac-why {
  position: relative;
  color: #FFFFFF;
  background-color: #0017E8;
  padding: 70px 0 250px 0;
  z-index: 4;
}
@media only screen and (min-width: 40.063em) {
  .ac-why {
    padding: 70px 0 30px 0;
  }
}
.ac-why .ac-logo {
  position: absolute;
  bottom: 144px;
  right: 15%;
  width: 400px;
  height: 400px;
  z-index: 3;
}
@media only screen and (min-width: 40.063em) {
  .ac-why .ac-logo {
    top: -570px;
    bottom: auto;
  }
}
.ac-why .ac-logo span {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  transform: rotate(-18deg) skew(-8deg, 0);
  position: absolute;
  top: 0;
  font-size: 650px;
  text-transform: uppercase;
  z-index: 2;
  padding: 40px;
}
@media only screen and (min-width: 40.063em) {
  .ac-why .ac-logo span {
    font-size: 900px;
  }
}
.ac-why .ac-logo span:nth-child(1) {
  z-index: 3;
  background: -webkit-linear-gradient(transparent, transparent), url('../images/auch-cool-intro.jpg') no-repeat center;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.ac-why .ac-logo span:nth-child(2) {
  transform: translate(40px, -40px) rotate(-18deg) skew(-8deg, 0);
  color: #F83A07;
}
@media only screen and (min-width: 40.063em) {
  .ac-why .ac-logo span:nth-child(2) {
    transform: translate(40px, -40px) rotate(-18deg) skew(-8deg, 0);
  }
}
.ac-why .wrapper {
  position: relative;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 4;
}
.ac-why h2 {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  color: #F83A07;
  margin: 0 0 30px 0;
  text-transform: uppercase;
  width: 100%;
  font-size: 50px;
  line-height: 50px;
}
@media only screen and (min-width: 40.063em) {
  .ac-why h2 {
    width: 50%;
    font-size: 36px;
    margin: 0 0 10px 0;
    line-height: normal;
  }
}
.ac-why p {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 25px;
  line-height: 32px;
  margin: 0;
  width: 100%;
}
@media only screen and (min-width: 40.063em) {
  .ac-why p {
    width: 50%;
    font-size: 30px;
    line-height: 37px;
  }
}
/*section*/
.ac-how {
  position: relative;
  padding-top: 290px;
}
@media only screen and (min-width: 40.063em) {
  .ac-how {
    padding-top: 80px;
  }
}
.ac-how svg {
  position: absolute;
  top: -1px;
  left: 0;
  fill: #0017E8;
  z-index: -1;
}
.ac-how h2 {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 80px;
  letter-spacing: 0px;
  text-align: center;
  color: #F83A07;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  font-size: 53px;
  line-height: 50px;
  text-transform: uppercase;
  margin-bottom: 95px;
  margin-top: 140px;
}
@media only screen and (min-width: 40.063em) {
  .ac-how h2 {
    width: 61%;
    margin-bottom: 150px;
  }
}
.ac-how .wrapper {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
}
.ac-how ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
  counter-reset: items;
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol {
    margin: 0 auto;
  }
}
.ac-how ol li {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 100%;
  padding: 40px 30px;
  margin-left: auto;
  margin-right: auto;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  counter-increment: section;
  /* Erhöht den Abschnittszähler */
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li {
    -ms-grid-columns: 1fr 100px;
    grid-template-columns: 1fr 100px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-gap: 50px;
    width: 61%;
    padding: 0;
    margin-bottom: 220px;
  }
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(1)::after {
    display: block;
    content: "";
    background-image: url('../images/Pfeil_01.svg');
    width: 100%;
    height: 160px;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -200px;
    left: 0;
  }
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(2)::after {
    display: block;
    content: "";
    background-image: url('../images/Pfeil_02.svg');
    width: 100%;
    height: 160px;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -200px;
    left: 0;
  }
}
.ac-how ol li img {
  width: 60px;
  margin-top: 28px;
  margin-right: 15px;
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li img {
    width: 100px;
  }
}
.ac-how ol li:nth-child(odd) {
  background-color: rgba(243, 245, 246);
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(odd) {
    background-color: transparent;
  }
}
.ac-how ol li:nth-child(1) div {
  grid-row: 1;
}
.ac-how ol li:nth-child(1) div:nth-child(1) {
  grid-column: 2;
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(1) div:nth-child(1) {
    grid-column: 1;
  }
}
.ac-how ol li:nth-child(1) div:nth-child(2) {
  grid-column: 1;
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(1) div:nth-child(2) {
    grid-column: 2;
  }
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(1) div:nth-child(2) {
    position: relative;
    -ms-flex-item-align: end;
    -ms-grid-row-align: end;
    align-self: end;
    /*
                        &::after {
                            content: "";
                            position: absolute;
                            left: 22px;
                            display: block;
                            background-image: url('./../images/icons/dashed.png');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            width: 3px;
                            height: 150px;
                        }
                        */
  }
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(2) {
    grid-template-columns: 100px 1fr;
  }
  .ac-how ol li:nth-child(2) div:nth-child(1) {
    position: relative;
    /*
                        &::after {
                            content: "";
                            position: absolute;
                            left: 12px;
                            display: block;
                            background-image: url('./../images/icons/dashed.png');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            width: 3px;
                            height: 150px;
                        }*/
  }
}
.ac-how ol li:nth-child(3) div {
  grid-row: 1;
}
.ac-how ol li:nth-child(3) div:nth-child(1) {
  grid-column: 2;
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(3) div:nth-child(1) {
    grid-column: 1;
  }
}
.ac-how ol li:nth-child(3) div:nth-child(2) {
  grid-column: 1;
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(3) div:nth-child(2) {
    grid-column: 2;
  }
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li:nth-child(3) div:nth-child(2) {
    -ms-flex-item-align: end;
    -ms-grid-row-align: end;
    align-self: end;
  }
}
.ac-how ol li h3 {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 80px;
  letter-spacing: 0px;
  margin: 0 0 10px 0;
  color: #0017E8;
  font-size: 35px;
  line-height: 39px;
  text-transform: uppercase;
}
.ac-how ol li h3::before {
  display: block;
  content: counter(section, decimal-leading-zero) ". ";
  font-size: 50px;
}
@media only screen and (min-width: 40.063em) {
  .ac-how ol li h3 {
    font-size: 25px;
  }
}
.ac-how ol li p {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
  margin: 0;
}
/*section*/
.ac-faqs {
  padding: 80px 0;
}
@media only screen and (min-width: 40.063em) {
  .ac-faqs {
    padding: 0 0 80px 0;
  }
}
.ac-faqs .wrapper {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
.ac-faqs h2 {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 60px;
  letter-spacing: 0px;
  color: #0017E8;
  border-bottom: 2px solid #000000;
  padding-bottom: 20px;
  margin-bottom: 15px;
  text-align: center;
}
@media only screen and (min-width: 40.063em) {
  .ac-faqs h2 {
    text-align: left;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}
.ac-faqs ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.ac-faqs ul li:not(:last-child) {
  border-bottom: 1px solid #000000;
}
/*section*/
.ac-contact {
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact {
    padding: 90px 0 0 0;
  }
}
.ac-contact .wrapper {
  position: relative;
  margin: 0 auto;
  width: #FFFFFF;
  max-width: 1200px;
  background-color: transparent;
  z-index: 2;
}
.ac-contact .ac-logo {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  display: none;
  position: absolute;
  top: -180px;
  left: -32%;
  font-size: 700px;
  text-transform: uppercase;
  transform: rotate(0deg) skew(12deg, 25deg);
  letter-spacing: -17px;
  background-image: linear-gradient(90deg, #0017E8, #832A71, #F83A07);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: visible;
  z-index: 2;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact .ac-logo {
    display: block;
  }
}
.ac-contact .grid-2 {
  position: relative;
  text-align: center;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact .grid-2.spacing {
    padding-bottom: 35px;
  }
}
@media only screen and (min-width: 85.063em) {
  .ac-contact .grid-2.spacing {
    padding-bottom: 155px;
  }
}
.ac-contact .grid-2:nth-of-type(1) div:nth-child(2) {
  margin-bottom: 90px;
}
.ac-contact .grid-2 div:nth-child(2) {
  margin-bottom: 40px;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact .grid-2 div:nth-child(2) {
    margin-bottom: 0;
  }
}
.ac-contact .grid-2.blue {
  background-color: #0017E8;
}
.ac-contact h2,
.ac-contact p {
  display: table;
  margin: 0 auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact h2,
  .ac-contact p {
    margin-left: 70px;
  }
}
.ac-contact h2 {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 60px;
  letter-spacing: 0px;
  margin-bottom: 10px;
  color: #0017E8;
}
.ac-contact p {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
  width: 90%;
  max-width: 390px;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact p {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 40.063em) {
  .ac-contact h2,
  .ac-contact p {
    text-align: left;
  }
}
.ac-contact svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  bottom: -1px;
  right: 0;
  width: 100vw;
  fill: #0017E8;
}
.ac-contact form {
  position: relative;
  max-width: 500px;
  z-index: 2;
  width: 90%;
  text-align: left;
  margin: 0 auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact form {
    margin-left: 70px;
  }
}
.ac-contact form label {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #FFFFFF;
  font-size: 13px;
  text-align: left;
}
.ac-contact form label input,
.ac-contact form label textarea {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
  display: block;
  border: none;
  border-bottom: 1px solid #FFFFFF;
  background-color: transparent;
  outline: none;
  font-size: 16px;
  color: #FFFFFF;
  width: 100%;
  margin: 10px 0 20px 0;
  padding: 5px 0;
}
.ac-contact form label textarea {
  resize: none;
  margin-bottom: 40px;
  margin-top: 10px;
  padding: 0;
}
.ac-contact form .ac-btn {
  margin: 70px auto 40px auto;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact form .ac-btn {
    margin: 0px 0 110px 0;
  }
}
.ac-contact form .grid-2 {
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
}
.ac-footer {
  background-color: #000000;
  color: rgba(105, 106, 107);
  display: grid;
  padding: 40px 20px;
  grid-template-columns: 1fr;
}
@media only screen and (min-width: 40.063em) {
  .ac-footer {
    grid-template-columns: 1fr 1fr;
    padding: 60px 20px 20px 20px;
  }
}
.ac-footer div:nth-child(1) {
  text-align: center;
}
@media only screen and (min-width: 40.063em) {
  .ac-footer div:nth-child(1) {
    text-align: left;
  }
}
.ac-footer div:nth-child(2) ul {
  font-size: 12px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
@media only screen and (min-width: 40.063em) {
  .ac-footer div:nth-child(2) ul {
    font-size: 16px;
  }
}
.ac-footer div:nth-child(2) ul li {
  display: inline-block;
}
@media only screen and (min-width: 40.063em) {
  .ac-footer div:nth-child(2) ul {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
.ac-footer ul {
  position: relative;
  z-index: 2;
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
  justify-content: center;
}
@media only screen and (min-width: 40.063em) {
  .ac-footer ul {
    justify-content: start;
  }
}
.ac-footer ul li {
  margin-right: 5px;
}
.ac-footer ul li:not(:last-child)::after {
  content: "/";
  display: inline-block;
  margin-left: 5px;
}
.ac-contact-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: #000000;
  color: #FFFFFF;
  overflow: scroll;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact-modal {
    padding-top: 30px;
  }
}
.ac-contact-modal p:not(.ac-contact-modal__headline):not(.ac-contact-modal__subline) {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact-modal__headline,
  .ac-contact-modal__subline {
    height: 130px;
  }
}
.ac-contact-modal__headline {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  color: #0017E8;
  font-size: 65px;
  line-height: 65px;
  max-width: 300px;
  margin: 0 auto 40px auto;
  text-align: center;
}
.ac-contact-modal__subline {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  color: #0017E8;
  font-size: 55px;
  line-height: 55px;
  max-width: 300px;
  margin: 0 auto 55px auto;
  text-align: center;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact-modal__subline {
    font-size: 46px;
    line-height: 46px;
  }
}
@media only screen and (min-width: 40.063em) {
  .ac-contact-modal__subline-text {
    height: 68px;
    margin: 0 0 43px 0;
  }
}
.ac-contact-modal__close-btn {
  outline: none;
  position: absolute;
  top: 10px;
  right: 5px;
  width: 40px;
  height: 40px;
  border: none;
  z-index: 22;
  background-color: transparent;
}
.ac-contact-modal__close-btn svg {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  stroke: #FFFFFF;
}
.ac-contact-modal form:not(.grid-2) {
  height: 100%;
  width: 90%;
  margin: 0 auto;
  padding: 25px 0 60px 0;
  max-width: 1200px;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact-modal form:not(.grid-2) {
    padding: 60px 0;
  }
}
@media only screen and (min-width: 40.063em) {
  .ac-contact-modal form:not(.grid-2):not(.isBusiness) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
    padding-top: 0;
  }
}
.ac-contact-modal form:not(.grid-2) .ac-btn.red {
  margin-bottom: 20px;
}
.ac-contact-modal form:not(.grid-2) div.is--hidden {
  display: none;
}
.ac-contact-modal form:not(.grid-2).isBusiness {
  max-width: 600px;
  padding-top: 0;
}
.ac-contact-modal form:not(.grid-2).isBusiness .ac-contact-modal__headline {
  max-width: 400px;
}
.ac-contact-modal form:not(.grid-2) .ac-contact-modal__content-2 {
  display: none;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact-modal form:not(.grid-2) .ac-contact-modal__content-2 {
    display: block;
  }
}
.ac-contact-modal form:not(.grid-2) .ac-contact-modal__content-2.show {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 60px 5% 0 5%;
  background-color: #000000;
  top: 0;
  left: 0;
  z-index: 2;
}
.ac-contact-modal .grid-2 {
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.ac-contact-modal label {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #FFFFFF;
  font-size: 16px;
  text-align: left;
}
.ac-contact-modal label input,
.ac-contact-modal label textarea {
  display: block;
  border: none;
  border-bottom: 1px solid #FFFFFF;
  background-color: transparent;
  outline: none;
  width: 100%;
  margin: 10px 0 30px 0;
  height: 30px;
  color: #FFFFFF;
  font-size: 18px;
}
.ac-contact-modal label.ac-select {
  font-family: "Crossten", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
  position: relative;
  display: block;
  border: none;
  border-bottom: 1px solid #FFFFFF;
  background-color: transparent;
  width: 100%;
  margin: 40px 0 30px 0;
}
.ac-contact-modal label.ac-select select {
  display: block;
  outline: none;
  appearance: none;
  font-size: 16px;
  padding-bottom: 10px;
  margin-top: 15px;
  width: 100%;
  border: none;
  color: rgba(243, 245, 246);
  background-color: transparent;
}
.ac-contact-modal label.ac-select:after {
  content: "";
  pointer-events: none;
  transform: rotate(135deg) translateY(-50%);
  position: absolute;
  bottom: 13%;
  right: 10px;
  display: block;
  width: 0;
  height: 0;
  border-width: 4px;
  border-style: solid;
  border-bottom-color: transparent;
  border-left-color: transparent;
  margin: 10px;
}
.ac-contact-modal label textarea {
  resize: none;
}
.ac-contact-modal label.ac-file-input {
  display: table;
  color: #F83A07;
  border-bottom: 1px solid #F83A07;
  margin-bottom: 50px;
}
.ac-contact-modal label.ac-file-input input[type="file"] {
  display: none;
}
.ac-contact-modal__pagination {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  bottom: 10px;
  display: block;
  font-size: 35px;
}
.ac-contact-modal__pagination span {
  color: #0017E8;
}
.ac-contact-modal__pagination button {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 35px;
  background-color: transparent;
  border: none;
  color: #0017E8;
}
@media only screen and (min-width: 40.063em) {
  .ac-contact-modal__pagination {
    display: none;
  }
}
.ac-modal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  z-index: 99;
}
.ac-modal__wrapper {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  max-width: 600px;
  padding: 30px 20px;
  background-color: #000000;
  color: #FFFFFF;
}
.ac-modal__title {
  font-family: acumin-pro-extra-condensed, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 60px;
  letter-spacing: 0px;
  margin: 0 0 20px 0;
  color: #0017E8;
}
.ac-modal__close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  padding: 0;
}
.ac-modal__close svg {
  stroke: #0017E8;
}
